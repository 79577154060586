
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.create-ticket {
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  @include for-size(phone-portrait-down) {
    padding: 20px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &-sub-title {
      color: #B5B7C0;
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__desc {
    margin: 12px 0 0 0;
    color: #797E8B;
    font-size: 14px;
    line-height: 20px;
  }
  &__tags {
    margin: 20px 0 0 0;
    @include for-size(phone-portrait-down) {
      margin: 10px 0 0 0;
    }
    .esmp-tag {
      margin: 5px 8px 0 0;
    }
  }
  &__form {
    margin: 24px 0 0 0;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid #E8E8EE;
    &--frame-mode {
      margin: 0;
    }
    &-group-checkbox {
      margin: 36px 0 0 0;
      @include for-size(phone-portrait-down) {
        margin: 18px 0 0 0;
      }
    }
    @include for-size(phone-portrait-down) {
      padding: 12px;
    }
  }
  &__add-watchers {
    margin: $base-gutter 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include for-size(phone-portrait-down) {
      margin: math.div($base-gutter, 2) 0;
    }
  }
  &__base-control {
    margin-top: 26px;
    .hm-form__element {
      &-hint {
        margin: 8px 0 0 0;
        font-size: 12px;
        line-height: 16px;
        transition: all ease 0.3s;
        &--error {
          color: $color-red;
        }
      }
      &-wrapper_error {
        .hm-form__element-label, .hm-form__element-hint {
          color: $color-red;
        }
        .esmp-upload-drag {
          border-color: #ff0c0ccc;
        }
        .esmp-input .esmp-input-element,
        .esmp-textarea__input,
        .esmp-select-head {
          border: 1px #ff0c0ccc solid;
        }
      }
    }
  }
  &__hint {
    color: $color-gray-status-op-60;
    margin-bottom: 10px;
  }
  &__fired-user {
    margin-top: 20px;
  }
  &__watchers-label {
    margin-bottom: $base-gutter;
  }
  &__watcher-list {
    display: flex;
    flex-direction: column;
    width: max-content;
  }
  &__watcher {
    margin-bottom: $base-gutter;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    .esmp-user-avatar {
      width: 32px;
      height: 32px;
      margin-right: $base-gutter;
    }
  }

  &__watcher-name {
    display: flex;
    align-items: center;
    margin-right: math.div($base-gutter, 2);
    transition: color $base-animation-time;

    @include hover(true) {
      color: $primary-color-hover;
    }
  }
}
.customer-users {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0 0 0;
  &__add-user {
    padding: 0;
  }
  &__element {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 16px;
    &-avatar {
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
    &-fullname {
      font-size: 12px;
      line-height: 16px;
      margin: 2px 0 0 0;
    }
  }
}
.customer-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  &__search {
    margin: 20px 0 0 0;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0 0 0;
  }
  &__avatar {
    margin: 0 20px 0 0;
    width: 40px;
    height: 40px;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    transition: color 0.3s;
    &-fio {
      margin: 0 16px 0 0;
    }
    &_no-access {
      color: $color-red;
    }
  }
  &__no-access {
    color: $color-red;
    @include for-size(phone-portrait-down) {
      font-size: 10px;
      line-height: 12px;
    }
  }
  &__email {
    display: inline-block;
    color: rgba(16, 24, 40, 0.4);
  }
  &__appointment-name {
    color: $color-primary-1-day;
  }
  &__email, &__appointment-name {
    font-size: 12px;
    line-height: 16px;
  }
  &__delete {
    cursor: pointer;
    margin-left: auto;
  }
}

.page-create-ticket {
  &__news {
    margin-bottom: 30px;

    .esmp-collapser__icon {
      color: $color-error-night;
    }
  }

  .ticket-news-item {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: math.div($gap, 2);
    }

    &__image {
      background: $color-grayscale-20;
      border-radius: $base-border-radius;
      margin-right: math.div($gap, 2);
      width: 120px;
      height: 60px;
      overflow: hidden;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
